<template>
  <v-container>
    <div
      class="d-flex align-space-between align-md-center justify-space-between flex-column flex-md-row mb-8"
    >
      <header-view :title="'Equipes'" class="mb-8 mb-md-0" />

      <section class="d-flex justify-end">
        <switch-show-type />

        <v-btn
          color="primary"
          class="black--text"
          depressed
          disabled
          @click="createLeague()"
        >
          Criar novo
        </v-btn>
      </section>
    </div>

    <filter-input
      class="mb-8"
      :dataFilters="require('@/assets/league/filters/teams.json')"
    />

    <!-- grid mode -->
    <card-grid
      v-if="$store.getters.viewType === 'grid'"
      :items="_teams"
      :loading="loading"
      :pagination="pagination"
      @submit="openTeam($event)"
      @update:pagination="handlePagination($event)"
    />

    <!-- list mode -->
    <custom-table
      v-else
      :headers="headers"
      :items="teams"
      :pagination="pagination"
      :loading="loading"
      @update:pagination="handlePagination($event)"
    >
      <template v-slot:[`item.team`]="{ item }">
        <v-list-item class="px-0">
          <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp" tile>
            <v-img
              :src="item.logo || require('@/assets/league/empty-logo.png')"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <router-link
                class="overtext--text"
                :to="`/league/team-details/${item.id}`"
              >
                {{ item.name }}
              </router-link>
            </v-list-item-title>

            <v-list-item-subtitle v-if="item.tag">
              [{{ item.tag }}]
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar v-if="$vuetify.breakpoint.xsOnly" tile>
            <v-img
              :src="item.logo || require('@/assets/league/empty-logo.png')"
            />
          </v-list-item-avatar>
        </v-list-item>
      </template>

      <template v-slot:[`item.league`]="{ item }">
        {{ item.league.name }} (x{{ item.league.x }})
      </template>

      <template v-slot:[`item.game`]="{ item }">
        {{ handleGame(item.league.gameId) }}
      </template>

      <template v-slot:[`item.participants`]="{ item }">
        <div v-if="item.team_players && item.team_players.length > 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ item.team_players[0].user.nickname }}

                <v-icon left>mdi-menu-down</v-icon>
              </div>
            </template>

            <div class="d-flex flex-column">
              <span v-for="(item, i) in item.team_players" :key="i">
                {{ item.user.nickname }}

                <v-icon x-small right>
                  {{ item.ready ? "mdi-check-all" : "mdi-check" }}
                </v-icon>
              </span>
            </div>
          </v-tooltip>
        </div>

        <div v-else>
          {{ item.user.nickname }}
        </div>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>
    </custom-table>
  </v-container>
</template>

<script>
import { getTeams } from "@/services/league/teams.js";
import { getGames } from "@/services/league/games.js";
import { formatDate } from "@/utils";

export default {
  data() {
    return {
      loading: true,
      pagination: {
        page: 0,
        size: 20,
        total: 1,
      },
      headers: [
        {
          text: "Time",
          value: "team",
        },
        {
          text: "Liga",
          value: "league",
        },
        {
          text: "Jogo",
          value: "game",
        },
        {
          text: "Participantes",
          value: "participants",
        },
        {
          text: "Inscrição",
          value: "createdAt",
        },
        {
          text: "Confirmação",
          value: "updatedAt",
        },
      ],
      teams: [],
      games: [],
    };
  },

  beforeMount() {
    this.getData();
    this.getGames();
  },

  watch: {
    ["$route.query"](value) {
      this.handleQuery(value);
    },
  },

  computed: {
    _teams() {
      return this.teams.map((e) => {
        return {
          id: e.id,
          image: e.logo || require("@/assets/league/empty-logo.png"),
          title: `${e.name} ${e.tag ? `[${e.tag}]` : ""}`,
          subtitle: `${e.league.name} (x${e.league.x})`,
        };
      });
    },
  },

  methods: {
    async getData(filter = {}) {
      try {
        const payload = {
          page: this.pagination.page,
          size: this.pagination.size,
          ...filter,
        };

        await getTeams(payload).then((res) => {
          this.teams = res.data;
          this.pagination.total = res.total;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async getGames() {
      try {
        const payload = {
          page: 0,
          size: 99,
        };

        await getGames(payload).then((res) => {
          this.games = res.data;
        });
      } catch (err) {
        console.log(err);
      }
    },

    handleGame(value) {
      const gameName = this.games.find((e) => e.id === value);
      if (gameName) return gameName.name;

      return "";
    },

    openTeam(event) {
      this.$router.push({ path: `/league/team-details/${event}` });
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 0;
      this.getData(value);
    },

    handlePagination(event) {
      this.pagination.page = event;
      this.getData();
    },

    formatDate,
  },
};
</script>

<style></style>
